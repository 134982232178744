<template>
  <!-- App.vue -->
  <!--  -->
  <v-app>
    <v-app-bar
      app
      style="background-color:rgb(255, 255, 255);"
      class="elevation-0"
    >
      <v-btn
        class="black--text"
        text
        onclick="window.history.back()"
        v-if="part == 'menu'"
      >
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>

      <v-btn
        text
        class="black--text"
        @click="part = 'menu'"
        v-if="part != 'menu'"
      >
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
      <v-spacer></v-spacer>

    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="p-0 bg py-5" style="background-color: rgb(235, 236, 239);">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-img">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 col-sm-5 col-md-4 d-flex justify-content-center align-items-center"
                    style="text-align: center;"
                  >
                    <img
                      @click="SelecFile()"
                      name="myImage"
                      :src="$store.state.ngayi.image"
                      class="img-fluids curseur"
                      width="200px"
                    />

                    <input
                      type="file"
                      id="file"
                      @change="baPoniImages($event)"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      style="display:none;"
                    />
                  </div>
                  <div
                    class="col-12 col-sm-7 col-md-8 d-flex flex-column align-items-center white"
                  >
                    <div
                      class="d-flex flex-column justify-content-center align-items-start"
                      style="height: 100%;width: 100%;padding: 5px;"
                    >
                      <div class="container text-center text-md-left">
                        <h1
                          style="font-size: 30px;font-family: Amethysta, serif;color: var(--bs-gray-dark);"
                        >
                          {{
                            $store.state.ngayi.First_Name +
                              " " +
                              $store.state.ngayi.Last_Name
                          }}
                        </h1>
                        <p style="color: var(--bs-gray-900);font-size: 16px;">
                          {{ $store.state.ngayi.email }}
                          <br /><br />
                        </p>

                        <v-btn
                          class="white--text elevation-0"
                          @click="SelecFile()"
                          style="background-color: rgb(81, 32, 11);"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'arrow-left']"
                            class="fa-x mr-5 white--text"
                          />
                          Change Image
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {{ $store.state.ngayi }}

          <div class="container my-5">
            <!-- Personal -->
            <v-row class="py-5" justify="center">

              <v-col cols="12" lg="8" class="bg px-5 white">
                <v-card
                  height="100%"
                  class="bg-transparent elevation-0 pb-5 px-5"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <!-- PréLast_Name -->
                        <tr>
                          <td class="py-2 black--text">First Name:</td>
                          <td>
                            <v-text-field label="First_Name" v-model="First_Name" />
                          </td>
                        </tr>
                        <!-- Last_Name -->
                        <tr>
                          <td class="py-2 black--text">Last_Name:</td>
                          <td>
                            <v-text-field label="Last_Name" v-model="Last_Name" />
                          </td>
                        </tr>
                        <!-- Middle_Name -->
                        <tr>
                          <td class="py-2 black--text">Middle_Name:</td>
                          <td>
                            <v-text-field label="Middle_Name" v-model="Middle_Name" />
                          </td>
                        </tr>
                        <!-- Phone -->
                        <tr>
                          <td class="py-2 black--text">Phone Number:</td>
                          <td>
                            <v-text-field label="First_Name" v-model="phone" />
                          </td>
                        </tr>
                        <!-- Email -->
                        <tr>
                          <td class="py-2 black--text">Email Address:</td>
                          <td>
                            <v-text-field label="First_Name" v-model="email" />
                          </td>
                        </tr>
                        <!-- Date de naissance -->
                        <tr>
                          <td class="py-2 black--text">Date de naissance:</td>
                          <td>{{ date_of_birth }}</td>
                        </tr>
                        <!-- Sex -->
                        <tr>
                          <td class="py-2 black--text">Sex:</td>
                          <td>{{ sex }}</td>
                        </tr>
                        <tr>
                          <td class="py-2 black--text">School:</td>
                          <td>
                            {{school}}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div class="py-5">
                    <v-btn color="primary" small @click="postUsers()"
                      >Save</v-btn
                    >
                  </div>
                </v-card>
              </v-col>
            </v-row>


            <!-- Logout -->
            <v-row class="py-5" justify="center" v-if="part == 'setting'">
              <v-col cols="12" lg="10" class="px-5">
                <v-card
                  height="100%"
                  class="elevation-0 pb-5 px-5 bg-transparent"
                >
                  <h3 class="text-center mb-5 black--text">Logout</h3>
                  <v-divider></v-divider>
                  <v-simple-table class="bg-transparent">
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="py-2">Deconnection</td>
                          <td class="py-2">
                            <v-btn
                              small
                              @click="logout()"
                              color="red"
                              class="white--text my-2 elevation-0"
                              >Deconnecter</v-btn
                            >
                          </td>
                        </tr>
                        <!-- <tr>
                      <td class="py-2 black--text">Light Mode:</td>
                      <td class="py-2 black--text">
                        <v-switch v-model="switch1"></v-switch>
                      </td>
                    </tr> -->
                        <tr>
                          <td class="py-2 black--text">Enregitrer:</td>
                          <td class="py-2 black--text">
                            {{ $store.state.ngayi.date }}
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2 black--text">
                            Last_Name de la plateforme:
                          </td>
                          <td class="py-2 black--text"><b>waKongo</b></td>
                        </tr>
                        <tr>
                          <td class="py-2 black--text">Version:</td>
                          <td class="py-2 black--text"><b>1.0</b></td>
                        </tr>
                      </tbody>
                    </template> </v-simple-table
                  ><br /><br />

                  <v-divider></v-divider>
                  <span class="py-2 title black--text"
                    ><b>Created By Richero</b></span
                  ><br />
                  <v-btn
                    small
                    href="tel:3195193008"
                    color="red"
                    class="white--text"
                    >Call</v-btn
                  >
                  <br /><br />
                  <img :src="require('@/images/DSC02395.jpg')" height="300px" />
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-card-title class="title red--text font-weight-normal">
          {{ title }}:
        </v-card-title>

        <v-card-text>
          <v-text-field
            :label="label"
            v-model="texte"
            v-if="part != 'assurance' && part != 'document'"
          />
          <form>
            <input
              v-if="part == 'job'"
              class="form-control m-0 px-1 subtitle-2 font-weight-normal"
              type="date"
              v-model="date_of_birth"
              style="border:none;border-bottom:1px solid #888;color: #666;"
            />
            <!-- Business -->
            <v-text-field v-model="tel" label="Téléphone" />

            <v-text-field v-model="link" label="Site web" />

            <v-textarea
              rows="2"
              auto-grow
              label="Desciptions"
              v-model="descriptions"
              v-if="part == 'business'"
            ></v-textarea>

            <v-textarea
              rows="2"
              auto-grow
              label="Addresse"
              v-model="adresse"
              v-if="part == 'business'"
            ></v-textarea>

            <!-- <v-btn
              small
              color="primary darken-1"
              class="elevation-0"
              v-if="part == 'business'"
              @click="
                adresse =
                  $store.state.ngayi.adresse +
                  ', ' +
                  $store.state.ngayi.city +
                  ', ' +
                  'Iowa ' +
                  $store.state.ngayi.zipcode
              "
              >My own adress</v-btn
            > -->

            <div class="mt-5">
              <!-- Image 1 -->
              <img
                @click="SelecFileBusiness(1)"
                name="myImage"
                :src="image_1"
                v-if="image_1 != '' && part == 'business'"
                class="img-fluids curseur m-1"
                width="150px"
              />
              <img
                @click="SelecFileBusiness(1)"
                name="myImage"
                :src="require('@/images/noPic.png')"
                v-if="image_1 == '' && part == 'business'"
                class="img-fluids curseur m-1"
                width="150px"
              /><br />

              <!-- Image 2 -->
              <img
                @click="SelecFileBusiness(2)"
                name="myImage"
                :src="image_2"
                v-if="image_2 != '' && part == 'business'"
                class="img-fluids curseur m-1"
                width="150px"
              />
              <img
                @click="SelecFileBusiness(2)"
                name="myImage"
                :src="require('@/images/noPic.png')"
                v-if="image_2 == '' && part == 'business'"
                class="img-fluids curseur m-1"
                width="150px"
              /><br />

              <!-- Image 3 -->
              <img
                @click="SelecFileBusiness(3)"
                name="myImage"
                :src="image_3"
                v-if="image_3 != '' && part == 'business'"
                class="img-fluids curseur m-1"
                width="150px"
              />
              <img
                @click="SelecFileBusiness(3)"
                name="myImage"
                :src="require('@/images/noPic.png')"
                v-if="image_3 == '' && part == 'business'"
                class="img-fluids curseur m-1"
                width="150px"
              />

              <input
                type="file"
                id="fileBusiness"
                @change="baPoniImagesBusiness($event)"
                accept="image/png, image/gif, image/jpeg, image/jpg"
                style="display:none;"
              />
            </div>
          </form>
          <!-- Car Mark -->
          <v-text-field
            label="Automobile Model"
            v-model="Last_Name"
            v-if="part == 'automobile'"
          />
          <v-text-field
            label="Year"
            v-model="lieu_de_naissance"
            v-if="part == 'automobile'"
          />
          <span class="subtitle-1 mb-2" v-if="part == 'automobile'"
            >Color:</span
          >
          <div v-if="part == 'automobile'" class="grey lighten-2 p-3">
            <v-btn
              @click="color = 'red--text'"
              color="red"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'yellow--text'"
              color="yellow"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'grey--text'"
              color="grey"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'brown--text'"
              color="brown"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'orange--text'"
              color="orange"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'blue--text'"
              color="blue"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'black--text'"
              color="black"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'white--text'"
              color="white"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'green--text'"
              color="green"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'pink--text'"
              color="pink"
              class="elevation-1 m-1"
            />
            <v-btn
              @click="color = 'purple--text'"
              color="purple"
              class="elevation-1 m-1"
            />
            <div class="text-center p-3">
              <font-awesome-icon
                :icon="['fas', 'car']"
                :class="color + ' fa-5x'"
              />
            </div>
          </div>

          <!-- Assurance -->
          <div v-if="part == 'assurance'">
            <v-card class="mx-auto elevation-0" max-width="500">
              <!-- <div class="p-3">{{insurancesPick}}</div>
              <div class="p-3">{{lesAssurances}}</div> -->
              <v-list shaped>
                <v-list-item-group v-model="insurancesPick" multiple>
                  <template v-for="(item, i) in insurances">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                    <v-list-item
                      v-else
                      @click="baponiNgayi(item)"
                      :key="`item-${i}`"
                      :value="item"
                      active-class="primary black--text"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary darken-2"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </div>

          <!-- Documents -->
          <div v-if="part == 'document'">
            <v-card class="mx-auto elevation-0" max-width="500">
              <v-list shaped>
                <v-list-item-group v-model="documentsPick" multiple>
                  <template v-for="(item, i) in mydocuments">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                    <v-list-item
                      v-else
                      :key="`item-${i}`"
                      :value="item"
                      active-class="primary black--text"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary darken-2"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="red" text @click="dialog = false">
            Fermer
          </v-btn>
          <v-btn small color="green" text @click="sendI(toWho)">
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>


    <v-dialog persistent v-model="dialogQuestion" max-width="400">
      <v-card class="text-center py-3">
        <h6 class="py-5">Voulez-vous supprimer ce/cette {{ toWho }} ?</h6>
        <v-btn small color="success" class="elevation-0 m-2" @click="suprr()"
          >Oui</v-btn
        >
        <v-btn
          small
          color="red white--text"
          class="elevation-0 m-2"
          @click="dialogQuestion = false"
          >Non</v-btn
        >
      </v-card>
    </v-dialog>


    <v-dialog persistent v-model="dialogImageProfil" max-width="450">
      <v-card class="text-center">
        <v-card-title>
          <div class="text-center w-100">Update your profile Picture</div>
          <!-- <h5 class="text-center my-4 red--text">(En Format Passport)</h5> -->
        </v-card-title>

        <v-card-text>
          <img
            @click="SelecFile()"
            name="myImage"
            :src="$store.state.ngayi.image"
            class="img-fluids curseur"
            width="200px"
          />

          <input
            type="file"
            id="file"
            @change="baPoniImages($event)"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            style="display:none;"
          />
        </v-card-text>

        <v-card-actions>
          <div class="text-center w-100 pb-3">
            <v-btn
              small
              class="white--text elevation-0"
              @click="SelecFile()"
              style="background-color: rgb(11,18,81);"
            >
              <font-awesome-icon
                :icon="['fas', 'arrow-left']"
                class="fa-x mr-5 white--text"
              />
              Changez Image
            </v-btn>
            <v-btn
              small
              color="red white--text"
              v-if="$store.state.ngayi.image"
              class="elevation-0 m-2"
              @click="dialogImageProfil = false"
              >Fermer</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "@/db.js";

export default {
  data() {
    return {
      erreur: "",
      show: false,
      part: "menu",
      title: "",
      label: "",
      toWho: "",
      id: "",
      companieName: "",
      companies: [],
      item: "",
      lesAssurances: {
        life: "",
        car: "",
        health: "",
        home: ""
      },
      tab: "tab-4",
      switch1: false,
      texte: "",
      color: "black--text",
      dialog: false,
      dialogImageProfil: false,
      dialogName: false,
      dialogQuestion: false,
      dialogEdit: false,
      place: "",
      valeur: 50,
      family: [],
      schools: [],
      jobs: [],
      tel: "",
      descriptions: "",
      images: [],
      img: "",
      image_1: "",
      image_2: "",
      image_3: "",
      link: "",
      businesses: [],
      businessesEdit: [],
      automobiles: [],
      assurances: [],
      documents: [],
      mydocuments: [
        "Green Card",
        "ID Card",
        "Driver License",
        "Social Security",
        "Work Permit",
        "Passport",
        "Lease"
      ],
      insurancesPick: [],
      documentsPick: [],
      insurances: [
        "Life Insurance",
        "Car Insurance",
        "Health Insurance",
        "Home Insurance"
      ],
      email: "",
      Last_Name: "",
      Middle_Name: "",
      First_Name: "",
      phone: "",
      date_of_birth: "",
      lieu_de_naissance: "",
      sex: "",
      homme: "btn btn-outline-dark btn-lg",
      femme: "btn btn-outline-dark btn-lg",
      adresse: "",
      city: "",
      cities: [
        "Cedar Rapids",
        "Coralville",
        "Iowa City",
        "North Liberty",
        "Tiffin"
      ],
      zipcode: "",
      state: "Iowa",
      dialogWait: false,

      direction: "bottom",
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: false,
      bottom: true,
      left: true,
      transition: "slide-y-reverse-transition"
    };
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    }
  },
  created() {
    // alert(user.email);
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
    this.getPersonal();
    else {
      if (this.$store.state.ngayi.image == "") {
        this.dialogImageProfil = true;
      }
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  },
  methods: {
    modifierBusiness(x) {
      this.businessesEdit = x;
      this.dialogEdit = true;
    },
    theFormat(number) {
      return number.toFixed(0);
    },
    prep(value, title, label) {
      this.title = title;
      this.label = label;
      this.toWho = value;
      this.texte = "";

      this.Last_Name = "";
      this.Middle_Name = "";
      this.First_Name = "";
      this.sex = "";
      this.email = "";
      this.phone = "";
      this.adresse = "";
      this.city = "";
      this.zipcode = "";
      this.lieu_de_naissance = "";
      this.date_of_birth = "";

      this.dialog = true;
    },
    sendI(value) {
      // if (value == "school") this.postSchool();
      // if (value == "job") this.postJob();
      if (value == "business") this.postBusiness();
      // if (value == "automobile") this.postAuto();
      if (value == "assurance") this.postAssurance();
      // if (value == "document") this.postDocument();
      this.dialog = false;
    },
    /*******************************************************/
    getPersonal() {
      this.Last_Name = this.$store.state.ngayi.Last_Name;
      this.Middle_Name = this.$store.state.ngayi.Middle_Name;
      this.First_Name = this.$store.state.ngayi.First_Name;
      this.sex = this.$store.state.ngayi.sex;
      this.email = this.$store.state.ngayi.email;
      this.phone = this.$store.state.ngayi.phone;
      this.school = this.$store.state.ngayi.school;
      this.date_of_birth = this.$store.state.ngayi.date_of_birth;

      if (this.sex == "M") this.tiaSex(0);
      if (this.sex == "F") this.tiaSex(1);
      else this.tiaSex(-1);
      alert("sd");
    },
    getSetting() {
      var that = this;
      that.part = "setting";
    },
    /****************************************************/
    postUsers() {
      var that = this;
      db.collection("users")
        .doc(that.$store.state.ngayi.originalId)
        .update({
          First_Name: that.First_Name,
          Middle_Name: that.Middle_Name,
          Last_Name: that.Last_Name,
          phone: that.phone,
          email: that.email,
          date_of_birth: that.date_of_birth
        });
      alert("Successfully");
    },

    /****************************************************/
    validate() {
      this.$store.state.mutuYeyo = this.User;
      var d = new Date();
      d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = "mutuYango" + "=" + this.yanoyo + ";" + expires + ";";
      document.cookie = "bomba" + "=" + this.password + ";" + expires + ";";
    },
    tiaSex(value) {
      if (value == 0) {
        this.homme = "primary darken-1 black--text btn-lg";
        this.femme = "btn btn-outline-dark btn-lg";
        this.sex = "Male";
      } else if (value == 1) {
        this.homme = "primary darken-1 black--text btn-lg";
        this.femme = "btn btn-outline-dark btn-lg";
        this.sex = "Fenale";
      } else {
        this.homme = "btn btn-outline-dark btn-lg";
        this.femme = "primary darken-1 black--text btn-lg";
        this.sex = "Prefer not to say";
      }
    },
    cancelProfile(id, toWho) {
      this.toWho = toWho;
      this.id = id;
      this.dialogQuestion = true;
    },
    suprr() {
      db.collection(this.toWho)
        .doc(this.id)
        .delete();
      this.dialogQuestion = false;
      alert("Successfully");
    },
    logout() {
      var that = this;
      firebase
        .auth()
        .signOut()
        .then(function() {
          // Sign-out successful.
          document.cookie = "mutuYango=" + ";";
          document.cookie = "codeYango=" + ";";

          //Reinstallation
          that.$store.state.ngayi = {};
          that.$store.state.ye = {};
          that.$store.state.email = "";
          that.$store.state.psw = "";
          that.$store.state.news = {};
          that.$store.state.city = {};
          that.$store.state.users = [];
          that.$store.state.familyNotification = [];
          that.$store.state.family = [];
          that.$store.state.roommate = [];

          that.$router.push("/");
        })
        .catch(function(error) {
          // An error happened.
          alert(error.message);
        });
    },
    SelecFile() {
      this.$store.state.ImagesUpload = [];
      document.getElementById("file").click();
    },
    baPoniImages(e) {
      document.getElementById("file");
      this.dialogWait = true;

      var that = this;
      e.target.files.forEach(element => {
        // var filename = element.name;
        var tableau = "";

        var storageRef = firebase
          .storage()
          .ref("Pictures/" + this.$store.state.ngayi.email + "/" + "profil");
        var metadata = {
          contentType: "image/jpeg"
        };

        storageRef.put(element, metadata).then(function(downloadURL) {
          downloadURL.ref.getDownloadURL().then(function(url) {
            url = String(url);
            if (url !== undefined) {
              var b = url;
              tableau = b;
              console.log(tableau);
              that.dialogWait = false;
              /****************************/
              db.collection("users")
                .doc(that.$store.state.ngayi.originalId)
                .update({
                  image: tableau
                });
              /*************************/
              that.$store.state.ngayi.image = tableau;
            }
          });
        });
      });
    },
    SelecFileBusiness(img) {
      this.img = img;
      this.$store.state.ImagesUpload = [];
      document.getElementById("fileBusiness").click();
    },
    baPoniImagesBusiness(e) {
      document.getElementById("fileBusiness");
      this.dialogWait = true;

      var that = this;
      e.target.files.forEach(element => {
        var filename = element.name;
        var tableau = "";

        var img_name = "";

        if (that.img == 1) img_name = that.texte + "_image_1";
        else if (that.img == 11)
          img_name = that.businessesEdit.name + "_image_1";
        else if (that.img == 2) img_name = that.texte + "_image_2";
        else if (that.img == 22)
          img_name = that.businessesEdit.name + "_image_2";
        else if (that.img == 3) img_name = that.texte + "_image_3";
        else if (that.img == 33)
          img_name = that.businessesEdit.name + "_image_3";

        var storageRef = firebase
          .storage()
          .ref(
            "Business/" +
              this.$store.state.ngayi.email +
              "/" +
              filename +
              "_" +
              img_name
          );
        var metadata = {
          contentType: "image/jpeg"
        };

        storageRef.put(element, metadata).then(function(downloadURL) {
          downloadURL.ref.getDownloadURL().then(function(url) {
            url = String(url);
            if (url !== undefined) {
              var b = url;
              tableau = b;
              console.log(tableau);
              that.dialogWait = false;
              if (that.img == 1) that.image_1 = tableau;
              else if (that.img == 2) that.image_2 = tableau;
              else if (that.img == 3) that.image_3 = tableau;

              if (that.img == 11) that.businessesEdit.image_1 = tableau;
              else if (that.img == 22) that.businessesEdit.image_2 = tableau;
              else if (that.img == 33) that.businessesEdit.image_3 = tableau;
            }
          });
        });
      });
    },
    baponiNgayi(item) {
      this.item = item;
      var i = this.insurancesPick.indexOf(item);
      if (i == -1) {
        this.dialogName = true;
        this.dialog = false;
      } else {
        if (this.item == "Life Insurance") this.lesAssurances.life = "";
        else if (this.item == "Car Insurance") this.lesAssurances.car = "";
        else if (this.item == "Health Insurance")
          this.lesAssurances.health = "";
        else if (this.item == "Home Insurance") this.lesAssurances.home = "";
      }
      // alert(item);
    },
    tiaCompany() {
      if (this.item == "Life Insurance")
        this.lesAssurances.life = this.companieName;
      else if (this.item == "Car Insurance")
        this.lesAssurances.car = this.companieName;
      else if (this.item == "Health Insurance")
        this.lesAssurances.health = this.companieName;
      else if (this.item == "Home Insurance")
        this.lesAssurances.home = this.companieName;
      this.companieName = "";
      this.dialogName = false;
      this.dialog = true;
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  }
};
</script>

<style scoped>
tr td {
  cursor: pointer;
}

.card {
  border: none;
}

.card:hover,
.curseur {
  background-color: rgb(248, 207, 194);
  cursor: pointer;
}

.masthead {
  /* background: url('../../images/pexels-alexander-mils-2068975.jpg') no-repeat;
  background-size: cover; */
  background-color: #2962ff;
  height: 100px;
}

.bg-col-img {
  /* background: url("@/images/pexels-eberhard-grossgasteiger-2098427.jpg") */
    /* center / cover no-repeat; */
}

.slide-fade-enter-active {
  transition: all 0.8s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(200px);
  opacity: 0;
}

.bgCarte{
  /* background: url("@/images/0.jpg") no-repeat cover; */
  /* background: red; */
}
</style>
